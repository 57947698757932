import type {
  BusinessModel,
  ComparableConfigurations,
  DistributionGroup,
  EcosystemUser,
  MissionVision,
  ProfileDetails,
  ProjectDetails,
  Section,
  Statements,
  SubSection,
  ValueCapture,
  ValueCreation,
} from '@forgd/common'
import type { ComputedRef } from 'vue'

export interface TDContextResolved {
  currentSection: ComputedRef<Section>
  currentSubSection: ComputedRef<SubSection>
  tokenProfileDetails: ProfileDetails
  fields:
    ((slug: 'additional-project-details') => ProjectDetails)
    & ((slug: 'comparables-configurations') => ComparableConfigurations)
    & ((slug: 'token-value-creation') => ValueCreation)
    & ((slug: 'profile-details') => ProfileDetails)
    & ((slug: 'token-value-creation') => ValueCreation)
    & ((slug: 'token-value-capture') => ValueCapture)
    & ((slug: 'statements') => Statements)
    & ((slug: 'token-mission-vision') => MissionVision)
    & ((slug: 'ecosystem-users') => Array<EcosystemUser>)
    & ((slug: 'token-business-model') => BusinessModel)
    & ((slug: 'profile-details') => ProfileDetails)
    & ((slug: 'groups') => Array<DistributionGroup>)
    & (<T>(slug: string) => T)
}

export function useTDContext(): Promise<TDContextResolved> {
  return inject<() => Promise<TDContextResolved>>('tdContext')!()
}
